$maincolour: #98a099;
$secondcolour: #5f6d61;
$logo: '/images/rubbish-removal.png';
@mixin clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
}
